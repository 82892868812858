import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import serviceData from "../constants/serviceData";
import styled from "styled-components";

// Styled Components

const Container = styled.div`
  padding: 3rem 2rem;
  background-color: #1b1b1b;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: Arial, sans-serif; /* Added for better readability */
`;

const LoaderWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const ServiceImage = styled.img`
  max-width: 100%;
  height: auto;
  border: 4px solid #23d997;
  border-radius: 15px;
  margin: 2rem 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 700;
  text-align: left;
  margin-bottom: 1.5rem;
  color: #23d997;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Description = styled.p`
  font-size: 1rem;
  text-align: justify;
  line-height: 1.6; /* Text is now left-aligned */
  line-height: 1.8;
  margin-bottom: 2rem;
  color: #ccc;
  letter-spacing: 0.5px;
  max-width: 800px;
  margin: 0 auto;
`;

const SectionTitle = styled.h3`
  font-size: clamp(1.8rem, 4vw, 2.2rem);
  margin-left: 10px;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  color: #23d997;
  text-align: left; /* Align section title left */
`;

const SubservicesList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  justify-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
`;

const SubserviceItem = styled.li`
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  background-color: #333;
  border-radius: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: #23d997;
    transform: translateY(-5px);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  }

  &::before {
    content: "•";
    margin-right: 0.5rem;
    color: #ff5733;
  }
`;

const TechnologiesContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  padding: 0 1rem;
  max-width: 1200px;
  margin: auto;
`;

const Category = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const CategoryTitle = styled.h4`
  font-size: 1.8rem;
  font-weight: 600;
  color: #f0f0f0;
  margin-bottom: 0.5rem;
  text-align: left; /* Align title left */
`;

const DescriptionAndTechList = styled.div`
  display: flex;
  flex-direction: row; /* Stack items for smaller screens */
  justify-content: flex-start;
  gap: 2rem;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const TechnologyList = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 640px) {
    gap: 1rem;
    flex-direction: column;
  }
`;

const TechnologyItem = styled.div`
  font-size: 1rem;
  color: #ffffff;
  background: #333;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 200px;
  word-wrap: break-word;
  white-space: normal;
  flex-shrink: 0;

  &:hover {
    background-color: #23d997;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

// ServiceDetail Component

const ServiceDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [service, setService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchServiceById = async (id) => {
    return serviceData.find((service) => service?.id === id);
  };

  useEffect(() => {
    const fetchService = async () => {
      setIsLoading(true);
      try {
        const fetchedService = await fetchServiceById(
          location?.state?.id || id || "1"
        );
        setService(fetchedService);
      } catch (error) {
        console.error("Error fetching service:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchService();
  }, [id, location?.state?.id]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const renderTechnologies = (technologies) => {
    if (Array.isArray(technologies)) {
      return (
        <TechnologyList>
          {technologies.map((tech, index) => (
            <TechnologyItem key={index}>
              {tech.icon} {tech.name}
            </TechnologyItem>
          ))}
        </TechnologyList>
      );
    } else if (typeof technologies === "object") {
      return (
        <TechnologiesContainer>
          {Object.entries(technologies).map(([category, techList]) => (
            <Category key={category}>
              <CategoryTitle>
                {category.charAt(0).toUpperCase() + category.slice(1)}:
              </CategoryTitle>
              <TechnologyList>
                {techList.map((tech, index) => (
                  <TechnologyItem key={index}>
                    {tech.icon} {tech.name}
                  </TechnologyItem>
                ))}
              </TechnologyList>
            </Category>
          ))}
        </TechnologiesContainer>
      );
    }
    return null;
  };

  return (
    <Container>
      {isLoading ? (
        <LoaderWrapper>
          <ClipLoader
            color="red"
            loading={isLoading}
            size={50}
            cssOverride={override}
          />
        </LoaderWrapper>
      ) : (
        <>
          <Title>{service.title}</Title>
          <DescriptionAndTechList>
            <Description>{service.description}</Description>

            {/* Subservices Section */}

            {/* Technologies Section */}
            <div>
              <SectionTitle>Technologies We Use:</SectionTitle>
              {renderTechnologies(service.technologies)}
            </div>
          </DescriptionAndTechList>
        </>
      )}
    </Container>
  );
};

export default ServiceDetail;
