import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";

import { Layout, Description, ImageContainer, Hide } from "../styles";
// Animation
import { motion } from "framer-motion";
import { titleAnimation, fade, photoAnimation } from "../animation";
import Wave from "./Wave";

const Header = styled(motion.div)`
  @media (max-width: 540px) {
    display: flex; /* Enables flexbox */
  flex-direction: column; /* Stacks items vertically */
  align-items: center; /* Centers items horizontally */
  justify-content: center; /* Centers items vertically */
  height: 100%; /* Ensures it spans the full height of its container */

    }


  h2 {
    font-size: 4rem;
    @media (max-width: 540px) {
      font-size: 2.75rem;
    }
  }

  span {
    color: #23d997;
  }
`;

const AboutSection = () => {
  // Framer-motion variant
  const container = {
    hidden: { x: 100 },
    show: {
      x: 0,
      transition: {
        duration: 0.75,
        ease: "easeOut",
        staggerChildren: 0.8,
        when: "afterChildren",
      },
    },
  };

  return (
    <Layout>
      <Description style={{ zIndex: 2 }}>
        <Header
          variants={container}
          initial="hidden"
          animate="show"
          className="title"
        >
          <Hide>
            <motion.h2 variants={titleAnimation}>We transform your</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnimation}>
              <span>vision</span> into
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnimation}>
              <span>code.</span>
            </motion.h2>
          </Hide>
        </Header>

        <motion.p variants={fade}>
          From concept to launch, we provide end-to-end solutions to elevate your business.
        </motion.p>

        <Link className="non-styled-link"
            to="contact-us"
            smooth={true}
            duration={1000}
            offset={-55}
            >
          <motion.button variants={fade}>Contact Us</motion.button>
        </Link>
      </Description>

      <Wave />
    </Layout>
  );
};

export default AboutSection;
