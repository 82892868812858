import React from "react";
import styled from "styled-components";
import { FaLinkedinIn } from "react-icons/fa"; // Import LinkedIn icon
import logo from "../img/cropped_image.png"; // Import your logo image

const Footer = () => {
  return (
    <FooterContainer>
      {/* Logo */}
      <LogoContainer>
        <img src={logo} alt="Logo" />
      </LogoContainer>

      {/* Copyright */}
      <CopyrightText>&copy; 2024 Callisto Solutions. All rights reserved.</CopyrightText>

      {/* Social Icons */}
      <SocialIcons>
        <a
          href="https://www.linkedin.com/company/callistosolutions" // Replace with your LinkedIn profile URL
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </SocialIcons>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components
const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #282828;
  color: #ffffff;
  padding: 1.5rem 2rem;
  border-top: 1px solid #333;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  img {
    height: 40px; /* Adjust logo size */
  }
`;

const CopyrightText = styled.div`
  font-size: 0.85rem;

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const SocialIcons = styled.div`
  a {
    color: #ffffff;
    font-size: 1.2rem;
    transition: color 0.3s;

    &:hover {
      color: #23d997; /* Change to a highlight color */
    }
  }
`;
