// src/components/CaseStudyStyles.js

import styled from "styled-components";

// TextSection
export const TextSection = styled.section`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 2rem 0;
  text-align: left;

  .content {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: -0.1rem;
      color: #000000;
    }

    h2 {
      font-size: 1.2rem;
      font-weight: bold;
      color: #23d997;
      margin-bottom: -0.12em;
      text-transform: uppercase;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      margin-top: -0.10rem;
      color: #000000;
    }
  }
`;

// CaseStudyContainer
// Updated CaseStudyContainer
export const CaseStudyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background-color: #ffffff; /* Set to white or match the surrounding background */
`;


export const YellowBanner = styled.section`
  width: 100%;
  height: auto; /* Dynamic height based on content */
  min-height: 60vh; /* Ensure sufficient height for visual balance */
  background-color: #23d997;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 3rem 5%; /* Add padding for spacing */

  /* Set the background image */
  background-image: url("https://img.freepik.com/free-photo/autumn-leafs-tablet-display-technology-generative-ai_188544-19435.jpg?semt=ais_hybrid");
  background-size: 35%; /* Reduce size of the background image */
  background-position: calc(100% - 30px) center; /* Position it to the right */
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #23d997; /* Add transparency for better readability */
    z-index: 1;
  }

  .banner-content {
    text-align: left;
    max-width: 800px;
    position: relative;
    z-index: 2; /* Ensure text is above the overlay */
    line-height: 1.4;

    h1 {
      font-size: 4rem;
      font-weight: bold;
      margin: 0 0 1rem 0;
      color: #ffffff;
      word-wrap: break-word; /* Handle long words */
    }

    p {
      margin: 0;
      font-size: 1.5rem;
      line-height: 1.6;
      color: #f8f8f8;
      word-wrap: break-word;
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 2rem; /* Adjust padding for smaller screens */
    background-size: 35%; /* Adjust background size on mobile */
    background-position: right center;

    .banner-content {
      text-align: center; /* Center-align text */
    }
  }
`;


// WhiteSection
export const WhiteSection = styled.section`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 3rem 0;

  .content {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    h2 {
      font-size: 1.5rem;
      color: #23d997;
      margin: 0;
    }

    p {
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.8;
      text-align: left;
    }
  }
`;

// ProblemSection
export const ProblemSection = styled.section`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .text-content {
      flex: 3;
      margin-right: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color: #000000;
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

// YellowBox
export const YellowBox = styled.div`
  background-color: #23d997;
  color: #000000;
  padding: 1.5rem;
  border-radius: 15px;
  margin: 2rem auto;
  width: 90%;
  max-width: 1200px;

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.9rem;
    }
    width: 95%;
  }
`;

// SolutionSection
export const SolutionSection = styled.section`
  width: 100%;
  background-color: #23d997;
  color: #000000;
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .text-content {
      flex: 3;
      margin-left: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color: #000000;
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }
  }
`;

// ImpactSection
// Updated ImpactSection: Image on the left, text on the right
export const ImpactSection = styled.section`
  width: 100%;
  background-color: #ffffff; /* Matches page background */
  color: #000000;
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; /* Image on the left, text on the right */
    align-items: center;

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-start; /* Align image to the left */
      align-items: center;

      img {
        width: 100%;
        max-width: 500px; /* Ensure the image doesn't grow too large */
        height: auto;
        max-height: 400px; /* Maintain aspect ratio and prevent excessive height */
        border-radius: 8px; /* Optional: Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
      }
    }

    .text-content {
      flex: 3;
      margin-left: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color: #000000;
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997; /* Yellow highlight */
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column; /* Stack image and text on smaller screens */

      .image-container {
        margin-bottom: 2rem; /* Add spacing below the image */
      }

      .text-content {
        margin-left: 0; /* Remove left margin on smaller screens */
      }
    }
  }
`;

export const ProcessSection = styled.section`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 3rem 0;

  .content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row; /* Text on the left, image on the right */
    align-items: flex-start;

    .text-content {
      flex: 3;
      margin-right: 2rem;

      .highlight {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        hr {
          width: 40px;
          height: 4px;
          background-color: #000000;
          border: none;
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: #23d997;
        }
      }

      h1 {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.8;
        margin-bottom: 1rem;
      }
    }

    .image-container {
      flex: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 100%;
        max-width: 500px;
        height: auto;
        max-height: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
