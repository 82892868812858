import React from "react";
import styled from "styled-components";

const AboutSection = styled.section`
  width: 100%;
  background-color: #23d997; /* Darker aesthetic background */
  color: #000000; /* White text */
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    gap: 2rem;

   .text-content {
  flex: 3;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem; /* Reduced bottom margin */
    color: #1B1B1B; /* Golden color for the title */
  }

  h2 {
    font-size: 1.7rem;
    color: #1B1B1B; /* Light blue for subtitle */
    margin-bottom: 0.5rem; /* Reduced bottom margin for closer spacing */
  }

  p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #000000; /* Soft gray for readability */
    margin-top: 0; /* Ensure no top margin adds space */
  }

  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1B1B1B; /* Vibrant green for emphasis */
  }
}

    .image-container {
      flex: 2;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        max-width: 450px;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
        border: 2px solid #1B1B1B; /* Border to match the title color */
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      text-align: center;

      .image-container {
        margin-top: 2rem;
      }
    }
  }
`;

const AboutUsSection = () => {
  return (
    <AboutSection id="about-us">
      <div className="container">
        <div className="text-content">
          <h1>About Us</h1>
          <h2>Innovating with Web Development, App Development, and AI Technologies</h2>
          <p>
            At <span>Callisto Solutions</span>, we are driven by a passion for creating cutting-edge
            technology solutions. Our expertise lies in crafting state-of-the-art web and mobile
            applications, leveraging the power of AI to deliver transformative results for businesses
            worldwide.
            We specialize in designing scalable, secure, and user-friendly solutions that redefine
            the digital experience. With a commitment to innovation and quality, we aim to
            empower businesses to thrive in a fast-evolving technological landscape.
          </p>
        </div>
        <div className="image-container">
          <img
            src="https://areya.tech/wp-content/uploads/2022/12/image-1-compressed-scaled.jpg" // Replace with your actual image
            alt="Callisto Solutions"
          />
        </div>
      </div>
    </AboutSection>
  );
};

export default AboutUsSection;
