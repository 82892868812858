// src/components/CaseStudy.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CaseStudyContainer,
  YellowBanner,
  WhiteSection,
  ProblemSection,
  SolutionSection,
  ImpactSection,
  TextSection,
  YellowBox,
  ProcessSection,
  
} from "./CaseStudyStyles"; // Adjust import path if needed

// Case study data
const caseStudies = {
  ideas: {
    title: "IDEAS Foundation",
    subtitle: "Web Application",
    bannerText: "Empowering the Future",
    description:
      "Empowering street children with access to quality education and learning opportunities.",
    challenges:
      "Providing access to technology in underserved communities and building trust.",
    solution:
      "Leveraging community partnerships and affordable tech tools to bridge the education gap.",
    impact:
      "Over 5,000 children empowered with educational resources, and significant improvements in literacy rates.",
    process:
      "Our process focused on gathering local insights, building user-friendly technology, and iterating through feedback from educators and students to ensure usability and accessibility.",
    bannerImage:
      "https://img.freepik.com/free-photo/autumn-leafs-tablet-display-technology-generative-ai_188544-19435.jpg",
    problemImage: "https://via.placeholder.com/400x300", // Replace with real image
    solutionImage: "https://via.placeholder.com/400x300", // Replace with real image
    impactImage: "https://via.placeholder.com/400x300", // Replace with real image
    processImage: "https://via.placeholder.com/400x300",
  },
  aira: {
    title: "AIRA Photo Editor App",
    subtitle: "Mobile Application",
    bannerText: "Create and Share Beautiful Moments",
    description:
      "A user-friendly app to enhance, edit, and share photos with ease and creativity.",
    challenges: "Balancing powerful editing tools with an intuitive interface.",
    solution:
      "Developed a clean UI with smart AI tools for effortless photo editing and sharing.",
    impact:
      "Over 1 million downloads, thousands of positive reviews, and a community of creative users.",
    process:
      "Our process included deep market research, implementing machine learning tools for enhanced editing, and extensive testing with users to refine the experience.",
    bannerImage:
      "https://via.placeholder.com/1920x1080", // Replace with real image
    problemImage: "https://via.placeholder.com/400x300", // Replace with real image
    solutionImage: "https://via.placeholder.com/400x300", // Replace with real image
    impactImage: "https://via.placeholder.com/400x300", // Replace with real image
    processImage: "https://via.placeholder.com/400x300",
  },
  ssl: {
    title: "Students Social Life App",
    subtitle: "Platform for Students",
    bannerText: "Enhancing Campus Connections",
    description:
      "A platform designed to enhance students' social connections and organize campus events effectively.",
    challenges:
      "Ensuring secure data sharing and building a trustworthy student community.",
    solution:
      "Implemented privacy-first features with end-to-end encryption and seamless event management capabilities.",
    impact:
      "Connected over 50,000 students, facilitated 10,000+ campus events, and fostered meaningful relationships.",
    process:
      "The process included working closely with student groups, refining the platform's usability, and ensuring compatibility with existing campus systems.",
    bannerImage:
      "https://via.placeholder.com/1920x1080", // Replace with real image
    problemImage: "https://via.placeholder.com/400x300", // Replace with real image
    solutionImage: "https://via.placeholder.com/400x300", // Replace with real image
    impactImage: "https://via.placeholder.com/400x300", // Replace with real image
    processImage: "https://via.placeholder.com/400x300",
  },
  streetfeast: {
    title: "StreetFeast App",
    subtitle: "Food Sharing Platform",
    bannerText: "Connecting People Through Food",
    description:
      "An initiative to connect people with food-sharing opportunities, helping those in need.",
    challenges: "Facilitating safe, efficient, and ethical food distribution.",
    solution:
      "Built a robust logistics platform with user-friendly features for food-sharing operations.",
    impact:
      "Distributed over 10,000 meals to underserved communities and fostered a culture of giving.",
    process:
      "Our process involved identifying underserved communities, optimizing logistics for minimal waste, and creating partnerships with local organizations.",
    bannerImage:
      "https://via.placeholder.com/1920x1080", // Replace with real image
    problemImage: "https://via.placeholder.com/400x300", // Replace with real image
    solutionImage: "https://via.placeholder.com/400x300", // Replace with real image
    impactImage: "https://via.placeholder.com/400x300", // Replace with real image
    processImage: "https://via.placeholder.com/400x300",
  },
};
const CaseStudy = () => {
  const { id } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
   // Scroll to the top when the component mounts
   useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  useEffect(() => {
    setCaseStudy(caseStudies[id]);
  }, [id]);

  if (!caseStudy) {
    return (
      <CaseStudyContainer>
        <p>Case Study Not Found!</p>
      </CaseStudyContainer>
    );
  }

  return (
    <CaseStudyContainer>
      {/* Yellow Banner Section */}
      <YellowBanner>
  <div className="banner-content">
    <h1>{caseStudy.bannerText}</h1>
    <p>{caseStudy.description}</p>
  </div>
</YellowBanner>



      {/* Text Section Below Yellow Banner */}
      <TextSection>
        <div className="content">
          <h1>{caseStudy.title}</h1>
          <h2>{caseStudy.subtitle}</h2>
          <p>{caseStudy.description}</p>
        </div>
      </TextSection>

      {/* Problem Section */}
      <ProblemSection>
        <div className="content-container">
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Problem</span>
            </div>
            <h1>Key Challenges</h1>
            <p>{caseStudy.challenges}</p>
          </div>
          <div className="image-container">
            <img src={caseStudy.problemImage} alt="Problem" />
          </div>
        </div>
      </ProblemSection>

      {/* Yellow Box for Challenges */}
      <YellowBox>
        <h2>Challenges</h2>
        <p>{caseStudy.challenges}</p>
      </YellowBox>

      {/* Solution Section */}
      <SolutionSection>
        <div className="content-container">
          <div className="image-container">
            <img src={caseStudy.solutionImage} alt="Solution" />
          </div>
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Solution</span>
            </div>
            <h1>Our Approach</h1>
            <p>{caseStudy.solution}</p>
          </div>
        </div>
      </SolutionSection>

      {/* Process Followed Section */}
      <ProcessSection>
        <div className="content-container">
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Process Followed</span>
            </div>
            <h1>Our Workflow</h1>
            <p>{caseStudy.process}</p>
          </div>
          <div className="image-container">
            <img src={caseStudy.processImage} alt="Process" />
          </div>
        </div>
      </ProcessSection>

    {/* Impact Section */}
    <ImpactSection>
        <div className="content-container">
          <div className="image-container">
            <img src={caseStudy.impactImage} alt="Impact" />
          </div>
          <div className="text-content">
            <div className="highlight">
              <hr />
              <span>Impact</span>
            </div>
            <h1>Results and Impact</h1>
            <p>{caseStudy.impact}</p>
          </div>
        </div>
      </ImpactSection>
    </CaseStudyContainer>
  );
};

export default CaseStudy;
