import React from "react";

import { RiReactjsFill } from "react-icons/ri";
import { FaLaptop, FaMobileAlt, FaRobot, FaBug } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
import { RiAngularjsLine } from "react-icons/ri";
import { DiNodejs } from "react-icons/di";
import { SiNestjs } from "react-icons/si";
import { FaGolang } from "react-icons/fa6";
import { DiMongodb } from "react-icons/di";
import { TbBrandMysql } from "react-icons/tb";
import { BiLogoPostgresql } from "react-icons/bi";
import { TbBrandReactNative } from "react-icons/tb";
import { SiDjango } from "react-icons/si";
import { IoFlaskSharp } from "react-icons/io5";
import { FaJava } from "react-icons/fa";
import { SiKotlin } from "react-icons/si";
import { SiTensorflow } from "react-icons/si";
import { SiMariadb } from "react-icons/si";
import { SiPytorch } from "react-icons/si";
import { AiFillOpenAI } from "react-icons/ai";
import { SiSelenium } from "react-icons/si";
import { GiJesterHat } from "react-icons/gi";
import { SiCypress } from "react-icons/si";
import { GrGraphQl } from "react-icons/gr";

const serviceData = [
  {
    id: 1,
    title: "Web App Development",
    shortDesc:
      "We create responsive, scalable, and visually appealing websites using cutting-edge technologies to elevate your brand.",
    description:
      "We create responsive, scalable, and visually appealing websites using cutting-edge technologies to elevate your brand. " +
      "Our expert developers ensure seamless performance across devices and browsers. " +
      "With a focus on user-centric design, we build interfaces that captivate and engage users. " +
      "Our backend solutions are robust, secure, and scalable to handle growing demands. " +
      "We leverage the latest frontend frameworks like React, Angular, and Next.js for interactive designs. " +
      "From startups to enterprises, our solutions are tailored to fit your needs. " +
      "We integrate powerful database systems like MongoDB, MySQL, and PostgreSQL for efficiency. " +
      "Collaborate with us for end-to-end solutions that transform ideas into reality. " +
      "Whether you need an e-commerce platform or a custom web app, we've got you covered. " +
      "Experience innovation and functionality with our expert web development team. " +
      "Our solutions ensure scalability and future-proofing as your business grows. " +
      "We use agile methodologies to deliver fast and iterative results, maintaining high-quality standards. " +
      "Our team is well-versed in integrating third-party services, APIs, and cloud solutions for enhanced functionality. " +
      "We prioritize code reusability and maintainability to ensure the long-term success of your web app. " +
      "Our goal is to enhance user engagement and boost conversion rates by offering personalized experiences through innovative web apps. " +
      "Stay ahead of the competition with our custom-tailored solutions that evolve with your needs.",
    icon: <FaLaptop size={50} color="#ffffff" />,
    direction: "left",
    subServices: [
      "Frontend Development",
      "Backend Development",
      "UI/UX Design",
    ],
    imgUrl:
      "https://t3.ftcdn.net/jpg/02/14/87/96/360_F_214879686_R3HFJlk6WLr1kcdvy6Q9rtNASKN0BZBS.jpg",
    technologies: {
      frontend: [
        { icon: <RiReactjsFill />, name: "ReactJs" },
        { icon: <RiAngularjsLine />, name: "AngularJs" },
        { icon: <TbBrandNextjs />, name: "NextJs" },
      ],

      backend: [
        { icon: <DiNodejs />, name: "NodeJs" },
        { icon: <SiNestjs />, name: "NestJs" },
        { icon: <FaGolang />, name: "GoLang" },
        { icon: <SiDjango />, name: "Django" },
        { icon: <IoFlaskSharp />, name: "Flask" },
        { icon: <GrGraphQl />, name: "GraphQl" },
      ],
      databases: [
        { icon: <DiMongodb />, name: "MongoDb" },
        { icon: <TbBrandMysql />, name: "MySql" },
        { icon: <BiLogoPostgresql />, name: "Postgres" },
        { icon: <SiMariadb />, name: "MariaDB" },
      ],
    },
  },
  {
    id: 2,
    title: "Mobile App Development",
    shortDesc:
      "Transform your ideas into interactive mobile applications for iOS and Android.",
    description:
      "Transform your ideas into interactive mobile applications for iOS and Android. " +
      "We specialize in native and cross-platform development for seamless user experiences. " +
      "Our solutions are tailored to deliver high performance and reliability. " +
      "From sleek designs to intuitive navigation, we create apps that captivate users. " +
      "We utilize frameworks like React Native and Flutter for cost-effective development. " +
      "Whether you need a startup MVP or an enterprise-level app, we've got you covered. " +
      "Our team ensures robust security and scalability to match evolving demands. " +
      "Integrate advanced features like push notifications and offline support. " +
      "We turn your vision into apps that stand out in competitive markets. " +
      "Experience creativity and innovation with our app development expertise. " +
      "Our development process is streamlined to ensure fast delivery without compromising on quality. " +
      "We ensure that your apps are optimized for performance and compatible across a wide range of devices. " +
      "Our user-centric design approach ensures that the apps are easy to navigate, engaging, and accessible to all. " +
      "Our team works collaboratively with you to provide constant updates, support, and improvements post-launch. " +
      "We incorporate the latest features and innovations to give your mobile app a competitive edge. " +
      "Partner with us for end-to-end app development that covers every stage from concept to launch.",
    icon: <FaMobileAlt size={50} color="#ffffff" />,
    direction: "right",
    subServices: [
      "iOS Development",
      "Android Development",
      "Cross-Platform Apps",
    ],
    imgUrl:
      "https://static8.depositphotos.com/1000128/1006/i/450/depositphotos_10062530-stock-photo-smartphone-with-cloud-of-application.jpg",
    technologies: [
      { icon: <TbBrandReactNative />, name: "React Native" },
      { icon: <FaJava />, name: "Java" },
      { icon: <SiKotlin />, name: "Kotlin" },
    ],
  },
  {
    id: 3,
    title: "AI Development",
    shortDesc:
      "Harness the power of artificial intelligence to automate processes, analyze data, and enhance decision-making.",
    description:
      "Harness the power of artificial intelligence to automate processes, analyze data, and enhance decision-making. " +
      "Our AI solutions are designed to boost efficiency and productivity in your business. " +
      "We develop intelligent chatbots for seamless customer interactions. " +
      "Build predictive models to anticipate trends and make data-driven decisions. " +
      "Utilize machine learning algorithms to uncover valuable insights. " +
      "Our team specializes in frameworks like TensorFlow and PyTorch for custom AI solutions. " +
      "We offer OpenAI-based tools for state-of-the-art conversational AI. " +
      "From startups to enterprises, we bring AI-driven innovation to every project. " +
      "Transform your operations with our cutting-edge AI services. " +
      "Collaborate with us for solutions that redefine the future of your business. " +
      "Our AI solutions are tailored to your specific business needs, providing impactful results. " +
      "We utilize natural language processing and machine vision to create AI that understands and reacts to real-world data. " +
      "With a focus on scalability, our AI models evolve with your business as it grows. " +
      "We provide AI consulting to guide your business through every step of AI implementation. " +
      "Our AI solutions are built for long-term success, providing value for years to come. " +
      "From predictive analytics to intelligent automation, we offer comprehensive AI solutions for every need.",
    icon: <FaRobot size={50} color="#ffffff" />,
    direction: "left",
    subServices: [
      "Chatbots",
      "Machine Learning Models",
      "Predictive Analytics",
    ],
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW6tAHe4IU1OvHzkIxH6TA-sdla7OrLM2Dng&s",
    technologies: [
      { icon: <SiTensorflow />, name: "TensorFlow" },
      { icon: <SiPytorch />, name: "PyTorch" },
      { icon: <AiFillOpenAI />, name: "OpenAI" },
    ],
  },
  {
    id: 4,
    title: "Software Quality Assurance",
    shortDesc:
      "Delivering reliable and bug-free software through comprehensive testing.",
    description:
      "Delivering reliable and bug-free software through comprehensive testing. " +
      "Our QA experts ensure every line of code meets the highest standards. " +
      "We specialize in both manual and automated testing for efficiency. " +
      "Identify and fix performance bottlenecks before they impact users. " +
      "Leverage advanced tools like Selenium, Jest, and Cypress for thorough testing. " +
      "From functionality to usability, we validate every aspect of your software. " +
      "Ensure cross-platform compatibility for seamless user experiences. " +
      "Minimize downtime with proactive testing and bug resolution. " +
      "We deliver software that’s robust, reliable, and ready for the market. " +
      "Partner with us for QA solutions that elevate the quality of your product. " +
      "Our team conducts rigorous performance testing to ensure the best user experience under various loads. " +
      "We provide continuous integration and testing to ensure consistent quality throughout the development cycle. " +
      "Our testing covers all possible scenarios, including edge cases and uncommon user behaviors. " +
      "We offer test automation services that save time and resources while maintaining accuracy. " +
      "Our testing process is transparent, providing detailed reports and insights into every phase. " +
      "We help you release software that is stable, fast, and free of critical bugs.",
    icon: <FaBug size={50} color="#ffffff" />,
    direction: "right",
    subServices: ["Manual Testing", "Automated Testing", "Performance Testing"],
    imgUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-m_I65tVMsJiUgzXxRgDtQuSw4Ew71zBC4g&s",
    technologies: [
      { icon: <SiSelenium />, name: "Selenium" },
      { icon: <GiJesterHat />, name: "Jest" },
      { icon: <SiCypress />, name: "Cypress" },
    ],
  },
];
export default serviceData;
