import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom"; // Import Switch and Route for v5
import { AnimatePresence, motion } from "framer-motion";
import GlobalStyles from "./components/GlobalStyles";
import Nav from "./components/Nav";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import OurSuccess from "./components/OurSuccess"; // Ensure correct import
import logo from "./img/cropped_image.png";
import CaseStudy from "./components/CaseStudy";
import Footer from "./components/Footer";
import ServiceDetail from "./components/ServiceDetail";

function App() {
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowSplash(false), 2000); // Splash screen duration: 2 seconds
    return () => clearTimeout(timer);
  }, []);

  const splashScreenVariants = {
    hidden: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 1, ease: "easeInOut" } },
  };

  const mainScreenVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
  };

  return (
    <div>
      <AnimatePresence>
        {showSplash ? (
          <motion.div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "linear-gradient(120deg, #1a1a1d, #36574b)",
              backgroundSize: "200% 200%",
              animation: "gradient 6s infinite ease-in-out",
              height: "100vh",
              width: "100vw",
              position: "absolute", // Full-screen overlay
              top: 0,
              left: 0,
              overflow: "hidden",
            }}
            variants={splashScreenVariants}
            initial="hidden"
            animate="hidden"
            exit="exit"
          >
            <style>{`
              @keyframes gradient {
                0% {
                  background-position: 0% 50%;
                }
                50% {
                  background-position: 100% 50%;
                }
                100% {
                  background-position: 0% 50%;
                }
              }
              .responsive-logo {
                height: 15.5rem; /* Default height */
              }
              @media (max-width: 768px) {
                .responsive-logo {
                  height: 10rem; /* Adjust height for mobile screens */
                }
              }
            `}</style>
            {/* Flex container for logo and text */}
            <motion.div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
                fontSize: "3.5rem",
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "bold",
                letterSpacing: "2px",
                textTransform: "uppercase",
              }}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              {/* Logo */}
              <img
                src={logo}
                alt="Callisto Logo"
                className="responsive-logo" // Add responsive class
              />
            </motion.div>
          </motion.div>
        ) : (
          <motion.div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
            variants={mainScreenVariants}
            initial="hidden"
            animate="visible"
          >
            <GlobalStyles />
            <Nav />
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={AboutUs} />
                <Route path="/work" component={OurSuccess} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/case-study/:id" component={CaseStudy} /> {/* Dynamic Route */}
                {/* Add more routes as needed */}
//              
//                 <Route path="/" exact>
//                   <AboutUs />
//                 </Route>
//                 <Route path="/work" exact>
//                   <OurSuccess />
//                 </Route>
//                 <Route path="/contact" exact>
//                   <ContactUs />
//                 </Route>
                <Route path="/work/:id" exact>
                  <CaseStudy />
                </Route>
                <Route path="/services/:id" exact>
                  <ServiceDetail />
                </Route>
              </Switch>
            </AnimatePresence>

            

            <Footer />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
