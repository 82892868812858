import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
// Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnimation } from "../animation";
import { Hide } from "../styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import axios from "axios";
const ContactStyle = styled(motion.section)`
  padding: 1.5rem;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #1b1b1b;
  background-color: #23d997;
  gap: 1rem;
  overflow: hidden;

  /* Tablet devices */
  @media only screen and (min-width: 540px) and (max-width: 820px) {
    padding: 3rem 1rem;
    flex-direction: column;
  }

  /* Mobile devices */
  @media only screen and (max-width: 539px) {
    padding: 2rem 1rem;
    flex-direction: column;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 28rem;
  min-height: 15rem;
  padding: 2rem;
  border-radius: 7px;
  background-color: #1b1b1b;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  overflow-x: hidden;

  label {
    color: #23d997;
    margin-top: 0.5rem; /* Adjusted for less margin */
    margin-bottom: 0.2rem; /* Adjusted for less margin */
    letter-spacing: 1.2px;
    font-size: 0.9rem;
  }

  input,
  textarea {
    font-family: inherit;
    padding: 8px;
    border-radius: 5px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    font-size: 0.9rem;
    margin-bottom: 0.8rem;

    &:focus {
      outline: 3px solid #23d997;
    }

    &::placeholder {
      color: #828282;
    }
  }

  textarea {
    resize: vertical;
    min-height: 80px;
  }

  button {
    background: #23d997;
    color: #1b1b1b;
    padding: 0.6rem 0.8rem;
    border-radius: 5px;
    margin-top: 1rem;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;

    &:hover {
      background: #1b1b1b;
      color: #23d997;
      transform: scale(1.05);
    }

    &:active {
      outline: 2px solid #f7f7f7;
    }
  }

  @media only screen and (max-width: 539px) {
    width: 95%;
    padding: 1.2rem;

    label {
      margin-top: 0.3rem; /* Reduced margin for mobile */
      margin-bottom: 0.2rem; /* Reduced margin for mobile */
    }
  }
`;

const FormTitle = styled(motion.div)`
  width: 90%;
  max-width: 35rem;

  h4 {
    color: #1b1b1b;
    font-size: 2rem;
    margin-bottom: 0.5rem; /* Adjusted for less margin */
  }

  p {
    color: #353535;
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0.5rem; /* Adjusted for less margin */
  }

  @media only screen and (max-width: 539px) {
    h4 {
      font-size: 1.5rem;
      margin-bottom: 0.3rem; /* Reduced margin for mobile */
    }
    p {
      font-size: 1rem;
      margin-bottom: 0.1rem; /* Reduced margin for mobile */
    }
  }
`;

const ContactForm = () => {
  const [isInView, setIsInView] = useState(false);
  const contactRef = useRef(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 } // Adjust threshold to trigger earlier or later
    );

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        observer.unobserve(contactRef.current);
      }
    };
  }, []);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://www.form-to-email.com/api/s/6JqFap56HgoG",
        {
          email,
          name,
          message,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("success");
        toast.success("Message sent successfully!");
        setEmail("");
        setName("");
        setMessage("");
      } else {
        toast.error("Something went wrong, please try again.");
        console.log("error");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error("Something went wrong, please try again.");
      setLoading(false);
      // Handle form-to-email server not responding
    }
  };
  return (
    <div>
      <ContactStyle
        ref={contactRef}
        variants={pageAnimation}
        initial="hidden"
        animate={isInView ? "show" : "hidden"}
        exit="exit"
        id="contact-us"
      >
        <FormTitle variants={titleAnimation}>
          <Hide>
            <motion.h4>Let’s Build Something Great Together</motion.h4>
            <motion.p>
              Reach out to us to discuss how we can bring your ideas to life,
              from web and mobile app development to AI solutions and quality
              assurance. Let’s create impactful digital experiences that drive
              your business forward.
            </motion.p>
          </Hide>
        </FormTitle>
        <FormContainer onSubmit={onSubmit}>
          <label>
            Your name <span>&#42;</span>
          </label>
          <input
            name="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Your Name"
            required
            aria-required
          />

          <label>
            Your email <span>&#42;</span>
          </label>
          <input
            name="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Your Email"
            required
            aria-required
          />

          <label>Your Message</label>
          <textarea
            name="message"
            rows="4"
            cols="50"
            wrap="hard"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message"
          ></textarea>

          {loading ? (
            <button type="submit" disabled={loading}>
              <ClipLoader color="#4A90E2" size={20} />
            </button>
          ) : (
            <button type="submit" disabled={loading}>
              Send Message
            </button>
          )}
        </FormContainer>
      </ContactStyle>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;
