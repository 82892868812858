import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import styled from "styled-components";
import logo from "../img/cropped_image.png"; // Import logo image
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = styled.nav`
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem; /* Adjusted padding for responsiveness */
  background: #282828;
  position: sticky;
  top: 0px;
  z-index: 5000;

  .logo img {
    height: 50px; /* Adjust logo size */
    cursor: pointer;
  }

  .menu-icon {
    display: none; /* Initially hide menu icon */

    @media (max-width: 768px) {
      display: block; /* Show on small screens */
      font-size: 1.8rem;
      color: #23d997;
      cursor: pointer;
    }
  }

  ul {
    display: flex;
    list-style: none;
    transition: all 0.3s ease;

    li {
      padding-left: 2rem;
      cursor: pointer;
      position: relative;
      color: #23d997;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      position: absolute;
      top: 10vh;
      right: 0;
      background: #1b1b1b;
      width: 100%;
      height: 0;
      overflow: hidden;
      padding: 0;
      visibility: hidden;
      opacity: 0;

      &.active {
        height: auto;
        padding: 1rem;
        visibility: visible;
        opacity: 1;
      }

      li {
        padding: 1rem 0;
        text-align: center;
      }
    }
  }

  .styled-link {
    color: #23d997;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #ffffff;
    }
  }
`;

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  const location = useLocation();
  return (
    <Navbar>
      {/* Logo Image */}
      <div className="logo">
        <NavLink id="logo" to="/" onClick={closeMenu}>
          <img src={logo} alt="Callisto Solutions Logo" />
        </NavLink>
      </div>

      {/* Hamburger Menu Icon */}
      <div className="menu-icon" onClick={toggleMenu}>
        {menuOpen ? "✖" : "☰"}
      </div>

      {location.pathname.includes("services") ? (
        <ul className={menuOpen ? "active" : ""}>
          <li>
            <NavLink className="styled-link" to="/" onClick={closeMenu}>
              Go back to home
            </NavLink>
          </li>
        </ul>
      ) : (
        <ul className={menuOpen ? "active" : ""}>
          <li>
            <NavLink className="styled-link" to="/" onClick={closeMenu}>
              Home
            </NavLink>
          </li>
          <li>
            <Link
              className="styled-link"
              to="our-services"
              smooth={true}
              duration={1000}
              offset={-30}
              onClick={closeMenu}
            >
              Services
            </Link>
          </li>

          <li>
            <Link
              className="styled-link"
              to="our-success"
              smooth={true}
              duration={1000}
              offset={-65}
              onClick={closeMenu}
            >
              Our Work
            </Link>
          </li>
          <li>
            {/* Add About Us link here */}
            <Link
              className="styled-link"
              to="about-us"
              smooth={true}
              duration={1000}
              offset={-65}
              onClick={closeMenu}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              className="styled-link"
              to="faq"
              smooth={true}
              duration={1000}
              offset={-55}
              onClick={closeMenu}
            >
              FAQ
            </Link>
          </li>
          <li>
            <Link
              className="styled-link"
              to="contact-us"
              smooth={true}
              duration={1000}
              offset={-75}
              onClick={closeMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      )}
    </Navbar>
  );
};

export default Nav;
